@mixin flag(
  $bg: #72879a,
  $color: #fff,
  $direction: right,
  $tail-length: 0.8em,
  $build-flag: true
) {
  // ======= Relative Variables =======
  $p: 0.3em;

  // ======= Flag Body =======
  @if $build-flag {
    // ~~ Display ~~
    display: inline-block;
    // ~~ Box Layout ~~
    padding: $p;
    padding-#{$direction}: $p;
    // ~~ Positioning ~~
    margin-#{$direction}: $tail-length;
    position: relative;
    // ~~ Typography ~~
    text-align: center;
    vertical-align: middle;
    line-height: 1;
  }
  // ~~ Themeing ~~
  color: $color;
  background: $bg;

  // ======= Flag Tails =======
  &:before,
  &:after {
    @if $build-flag {
      content: "";
      width: 0;
      height: 0;
      border-#{$direction}: $tail-length solid transparent;
      #{$direction}: -#{$tail-length};
      position: absolute;
      top: 0;
    }
    border-top: (0.5em + $p) solid $bg;
  }
  &:after {
    @if $build-flag {
      top: auto;
      bottom: 0;
    }
    border-top: none;
    border-bottom: (0.5em + $p) solid $bg;
  }
}

.flag {
  @include flag();
}

.flag-hot {
  @include flag($bg: red);
}

.flag-poppular {
  @include flag($bg: FireBrick);
}

.flag-jackpot {
  @include flag($bg: DarkOrange);
}

.flag-new {
  @include flag($bg: Maroon);
}

.flag-event {
  animation: pulse 0.5s infinite;
  font-weight: bold;
  border-radius: 5px;
}

@keyframes pulse {
  0% {
    background-color: #7e0414;
  }
  100% {
    background-color: #b9251d;
  }
}

.tag-copy {
  font-size: 12px;
  border-radius: 10px;
  border: 1px rgb(255, 255, 255) solid;
  padding: 2px 5px;
  border-radius: 4px;
  color: white;
}

.tag-copy:hover {
  font-size: 12px;
  border-radius: 10px;
  border: 1px rgb(255, 255, 255) solid;
  padding: 2px 5px;
  border-radius: 4px;
  color: #de006f;
}

.tag-game {
  position: absolute;
  transform: translate(0%, -15%);
  font-size: 14px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 1;
}

.tag-all-game {
  position: absolute;
  transform: translate(0%, -55%);
  font-size: 14px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 1;
}

.tag-all-camp {
  position: absolute;
  transform: translate(0%, 140%);
  font-size: 14px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 1;
}

@media all and (max-width: 709px) {
  .tag-game {
    position: absolute;
    transform: translate(0%, -15%);
    font-size: 12px;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 1;
  }

  .tag-all-game {
    position: absolute;
    transform: translate(0%, -55%);
    font-size: 14px;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 1;
  }

  .tag-all-camp {
    position: absolute;
    transform: translate(0%, 60%);
    font-size: 12px;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 1;
  }
}

.tag-game-line {
  position: absolute;
  color: rgb(255, 255, 255);
  background: linear-gradient(135deg, #181818c9 0%, #181818c9 100%);
  border-top-right-radius: 10px;
  border-bottom-left-radius: 7px;
  left: 7.6%;
  bottom: 0%;
  font-size: 14px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

@media all and (max-width: 709px) {
  .tag-game-line {
    position: absolute;
    bottom: 0%;
    color: rgb(255, 255, 255);
    background: linear-gradient(135deg, #181818c9 0%, #181818c9 100%);
    border-top-right-radius: 10px;
    border-bottom-left-radius: 7px;
    left: 7.6%;
    font-size: 14px;
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.tag-hot-game {
  width: 25px;
  height: 25px;
  z-index: 999;
  position: relative;
  background-image: url("https://storage.googleapis.com/sevenduck_bucket/snambet/hot-tag-casino.gif");
}
