span {
  color: #ffbe00;
}

.font-span {
  color: #ffbe00;
}

.line-span {
  border: solid 0.5px #ffbe00;
}

a {
  text-decoration-color: #ffbe00;
}

.font-bold {
  font-weight: bold;
}

.font-info {
  color: #f2f2f2;
  opacity: 0.5;
}

.font-color-link {
  color: #ffbe00;
}
.color-font-card {
  color: #ffffff;
}

.font-jackpot-ufa {
  margin-top: 8px;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 20px;
}

.font-jackpot {
  background: linear-gradient(to right, #d8bf9d 0%, #a78a59 50%, #d8bf9d 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 36px;
  letter-spacing: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.font-base {
  color: #ffffff !important;
}

.font-white {
  color: white;
}

.font-nav {
  color: #dfaf1f;
}

.font-nav:hover {
  color: #ffbe00;
}

.font-whiteblue {
  color: #ffffff;
}

.font-black {
  color: rgb(0, 0, 0);
}

.font-red {
  color: red;
}

.title-left {
  text-align: left;
}
