.btn-agent-purple {
  color: #000;
  font-weight: bold;
  background: #ffbe00;
}

.btn-agent-non-active {
  color: #ffffff;
  font-weight: bold;
  background: #2a2a2a;
}

.btn-choose-money-rps {
  background-color: transparent !important;
  border-color: #4bbde018 !important;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 0px;
}

.btn-choose-money {
  color: #ffffff !important;
  background-color: transparent !important;
  border-color: #e0cc4b18 !important;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 0px;
}

.btn-choose-money:hover {
  color: #ffffff !important;
  background-color: transparent !important;
  border-color: #d4af1c !important;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 0px;
}

.center-item {
  display: flex;
  justify-content: center;
}

.bg-purple-submit {
  display: block;
  width: 100%;
  color: #000;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  background: #ffbe00;
}

.btn-purple-submit {
  display: block;
  width: 100%;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  color: #000;
  background: #ffbe00;
}

.btn-purple-submit-subfix {
  display: block;
  width: 100%;
  color: #000;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  background: #ffbe00;
}

.btn-purple-submit:hover {
  display: block;
  width: 100%;
  color: #000;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  background: #ffbe00;
}

.background-blue-linear {
  display: block;
  width: 100%;
  border: none;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  background: linear-gradient(140deg, #73baf5 0%, #17c9ff 100%);
  padding-top: 7px;
  padding-bottom: 7px;
}

.background-blue-linear:hover {
  display: block;
  width: 100%;
  border: none;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  background: linear-gradient(140deg, #73baf5 0%, #17c9ff 100%);
  padding-top: 7px;
  padding-bottom: 7px;
}

@media all and (max-width: 709px) {
  .background-blue-linear {
    display: block;
    width: 100%;
    border: none;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    background: linear-gradient(140deg, #73baf5 0%, #17c9ff 100%);
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .background-blue-linear:hover {
    display: block;
    width: 100%;
    border: none;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    background: linear-gradient(140deg, #73baf5 0%, #17c9ff 100%);
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .btn-purple-submit {
    display: block;
    width: 100%;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #000;
    background: #ffbe00;
  }

  .btn-purple-submit:hover {
    display: block;
    width: 100%;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #000;
    background: #ffbe00;
  }

  .btn-playgame {
    width: 100%;
    color: #000000;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    background: #ffbe00;
    width: 90px;
  }

  .btn-try-play {
    display: block;
    width: 100%;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    background: #2a2a2a;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .btn-try-play:hover {
    display: block;
    width: 100%;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    background: #2a2a2a;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .btn-purple-back {
    display: block;
    width: 100%;
    border-style: solid;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    border-color: #ffbe00;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .btn-purple-back:hover {
    display: block;
    width: 100%;
    border-style: solid;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    border-color: #ffbe00;
    background: #ffbe00;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .btn-purple-close {
    display: block;
    width: 100%;
    border-style: solid;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    background: #ffbe00;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .btn-purple-close:hover {
    display: block;
    width: 100%;
    border-style: solid;
    color: #000000;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    background: #ffbe00;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.box-casino {
  position: absolute;
  top: 70%;
  left: 33%;
  transform: translate(-50%, -50%);
}

.box-casino-desktop {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-playgame {
  width: 100%;
  color: #000000;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  background: #ffbe00;
  width: 90px;
  border-radius: 6px;
}

.btn-try-play {
  display: block;
  width: 100%;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  background: #2a2a2a;
}

.btn-try-play:hover {
  display: block;
  width: 100%;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  background: #2a2a2a;
}

.btn-purple-back {
  display: block;
  width: 100%;
  border-style: solid;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  border-color: #ffbe00;
}

.btn-purple-back:hover {
  display: block;
  width: 100%;
  border-style: solid;
  color: #000;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  border-color: #ffbe00;
  background: #ffbe00;
}

.btn-purple-close {
  display: block;
  width: 100%;
  border-style: solid;

  cursor: pointer;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  background: rgb(30, 30, 30);
}

.btn-purple-close:hover {
  display: block;
  width: 100%;
  border-style: solid;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  background: rgb(30, 30, 30);
}

.bg-regis {
  border-radius: 6px;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  text-shadow: 1px 1px 1px rgba(31, 32, 32, 0.274);
  color: #ffffff;
  background: linear-gradient(140deg, #fdc243 0%, #f1ba42 100%);
}

.bg-invite {
  border-radius: 6px;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  color: #000000 !important;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  text-shadow: 1px 1px 1px rgba(31, 32, 32, 0.274);
  background: #ffbe00 !important;
}

.bg-invite-disable {
  border-radius: 6px;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  background: rgb(61, 61, 61);
}

.bg-nondis {
  border-radius: 6px;
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  text-shadow: 1px 1px 1px rgba(31, 32, 32, 0.274);
}

.bg-login {
  border-radius: 6px;
  display: block;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  color: #000000;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  text-shadow: 1px 1px 1px rgba(31, 32, 32, 0.274);
  background: #ffbe00;
}

.bg-blue {
  background-color: #181c38;
}

.bg-head-rps {
  background: #181c38;
}

.bg-blue-fade {
  background: linear-gradient(180deg, #1a1d35 50%, #2a2e54 100%);
}

.bg-money-balance {
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
  width: 100%;
  border: none;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  background: #181c38;
}

.bg-timer-checkin {
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
  font-size: 24px;
  letter-spacing: 3px;
  width: 100%;
  border: none;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  background: #181c38;
}

.bg-udiamond-nav {
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
  border: none;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  background: #181c38;
}

.border-radius {
  border-radius: 10px;
}

.border-radius-bank {
  border-radius: 4px;
}

.card-add-bank {
  border: none;
  border-radius: 5px;
  background: #292929;
}

.card {
  margin-top: 10px;
  border: none;
  border-radius: 10px;
  background: #181c38;
}

.card-udiamond {
  margin-top: 10px;
  border: none;
  border-radius: 10px;
  background: transparent;
}

.card-casino-game {
  background: #181c38;
}

.card-ufa-category {
  z-index: 0;
  background: linear-gradient(
    285deg,
    rgb(24, 24, 23) 20.73%,
    rgb(0, 0, 0) 76%,
    rgb(20, 18, 13) 50.57%
  );
}

.card-casino {
  margin-top: 0px;
  border: none;
  border-radius: 10px;
}

.card-ufa {
  overflow: hidden;
  border: 2px solid;
  border-radius: 8px;
  border-color: #ffbe00;
  z-index: 200;
  position: relative;
  margin-top: 0px;
  border-radius: 10px;
  background: linear-gradient(
    285deg,
    rgba(172, 127, 4, 1) 4.73%,
    rgba(101, 78, 24, 1) 42%,
    rgba(106, 83, 22, 1) 83.57%
  );
}

.card-ufa-comment {
  overflow: hidden;
  border: 2px solid;
  border-radius: 8px;
  border-color: #ffbe00;
  z-index: 200;
  position: relative;
  margin-top: 0px;
  border-radius: 10px;
  background: none;
}

.form-control {
  padding: 10px;
  border-radius: 6px;
}

.bottom-line-yellow {
  border-bottom: 1px solid #ffbe00;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 5px;
  margin-left: auto;
}

.bottom-line-regis {
  border-bottom: 10px solid #ffbe00;
  width: 80px;
  border-radius: 2px;
}

.bottom-line-casino {
  border-bottom: 3px solid #ffbe00;
  opacity: 0.3;
}

.bottom-line-black {
  border-bottom: 0.1px solid;
  border-color: #ffbe00;
  opacity: 0.4;
  width: 100%;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
}

.card-title {
  margin-bottom: 0.75rem;
  font-weight: 900;
  margin-top: 1.75rem;
}

.card-content {
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  padding-top: 0px;
}

.heading {
  position: relative;
  top: 20px;
}

.fade-color-blue {
  color: #ecf0f1;
  background: -webkit-linear-gradient(
    top,
    rgba(196, 192, 192, 0.39) 20%,
    rgba(94, 88, 88, 0.39) 70%,
    rgba(85, 118, 184, 0.356) 100%
  );
  border: solid 1px rgba(255, 255, 255, 0.3);
}

.arrow-tranfer {
  position: relative;
}

.arrow-tranfer span {
  display: block;
  width: 15px;
  height: 15px;
  border-bottom: 3px solid #ffbe00;
  border-right: 3px solid #ffbe00;
  transform: rotate(20deg);
  animation: animate 1.4s infinite;
}
.arrow span:nth-child(2) {
  animation-delay: -0.3s;
}
.arrow span:nth-child(3) {
  animation-delay: -0.5s;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(0px, 0px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(0px, 0px);
  }
}

.wrapper {
  margin-left: -8px;
  margin-right: -8px;
}

.grid {
  padding-left: 8px;
  padding-right: 8px;
}

.tabs-slot {
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  outline: none;
  white-space: nowrap;
}

.border-right-blue {
  border-right: solid 0.6px #ffbe00;
}

.active-tabs-slot {
  color: rgb(255, 255, 255) !important;
  border: none;
  background: linear-gradient(135deg, #de006f 0%, #f73736 100%);
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.icon-head {
  width: 20px;
  height: 20px;
}

.icon-profile {
  width: 40px;
  height: 40px;
}

.icon-fav-banner {
  width: 15px;
  height: 15px;
}

.icon-contacts {
  width: 80px;
  height: 80px;
}

.icon-allgame {
  width: 100px;
  height: 10px;
}

::-webkit-scrollbar {
  display: none;
}

.payment {
  z-index: 0;
  width: auto;
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.card-bank {
  width: 290px;
  height: 148px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 5px;
  background-image: linear-gradient(to bottom left, #bcf5ff, #4a95db);
  overflow: hidden;
  z-index: 5;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 15px 24px rgba(37, 44, 65, 0.32);
  box-shadow: 0 15px 24px rgba(37, 44, 65, 0.32);
}

.card-bank-vender {
  position: relative;
  margin: 0 auto;
  margin-bottom: 5px;
  background: linear-gradient(135deg, #3d5a8d 0%, #4c277c 100%);
  overflow: hidden;
  z-index: 5;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 5px 10px 10px rgba(24, 24, 24, 0.301);
  box-shadow: 5px 10px 10px rgba(37, 35, 37, 0.247);
  word-break: break-all;
}

.card-bank-content {
  padding-top: 2px;
  padding-left: 20px;
  padding-right: 15px;
  padding-bottom: 20px;
  z-index: 5;
}

.card-font-h1 {
  margin: 0;
  line-height: 2.8;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -2px;
  text-align: center;
}

.card-font-h1-h2 {
  line-height: 1.4;
  font-size: 11px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
}

.card-font-h3 {
  margin: 0;
  font-size: 18px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  line-height: 1.8;
}

.card-font-bank {
  margin: 0;
  font-size: 20px;
  letter-spacing: 4px;
  text-align: left;
  text-transform: uppercase;
  line-height: 1.8;
}

.box-banner-home-1 {
  display: none;
}

.box-banner-home-2 {
  display: block;
}

@media all and (min-width: 768px) {
  .box-banner-home-1 {
    display: block;
  }
  .box-banner-home-2 {
    display: none;
  }
}

.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

.top-left {
  position: absolute;
  top: 20%;
  left: 16px;
}

.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 12px;
}

.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-down) {
  0.0% {
    transform: rotate(-10deg);
  }
  100% {
    bottom: -20x;
    transform: rotate(10deg);
  }
  19.7% {
    bottom: -20x;
    transform: rotate(10deg);
  }
  39.9% {
    bottom: -20x;
    transform: rotate(-10deg);
  }
  60.2% {
    bottom: -20x;
    transform: rotate(10deg);
  }
  80.1% {
    bottom: -20x;
    transform: rotate(-10deg);
  }
}

.element {
  @include animation("slide-down 3s infinite normal none");
}

.dropdown-menu {
  background-color: #000000;
  padding: 0 !important;
}

#dropdown-basic::after {
  color: white;
}

#dropdown-basic {
  color: rgb(255, 255, 255);
}

#nav-dropdown {
  color: #ffffff !important;
}

#nav-dropdown:hover {
  color: #000000 !important;
  background-color: #ffbe00;
}

.view-background-gradinet {
  width: 190px;
}

.view-background-border-non-radius {
  overflow: hidden;
  border: 2px solid;
  border-color: #98813f;
}

.view-background-gradinet-nav {
  background: linear-gradient(
    285deg,
    rgba(172, 127, 4, 1) 4.73%,
    rgba(101, 78, 24, 1) 42%,
    rgba(106, 83, 22, 1) 83.57%
  );
}

.view-background-border-non-radius-green {
  overflow: hidden;
  border: 2px solid;
  border-color: #166a43;
}

.view-background-gradinet-green {
  background: linear-gradient(
    285.37deg,
    rgba(12, 223, 93, 0.67) 4.73%,
    rgba(22, 83, 30, 0.67) 71.98%,
    #166a43 83.57%
  );
}

.view-background-border-non-radius-red {
  overflow: hidden;
  border: 2px solid;
  border-color: #6a1616;
}

.view-background-gradinet-red {
  background: linear-gradient(
    285.37deg,
    rgba(223, 12, 12, 0.67) 4.73%,
    rgba(83, 22, 22, 0.67) 71.98%,
    #6a1616 83.57%
  );
}

.btn-green {
  color: #fff !important;
  background-color: transparent !important;
  border-color: #00ff47 !important;
}

.btn-green:hover {
  color: #000 !important;
  background-color: #00ff47 !important;
  border-color: #00ff47 !important;
}

.btn-yellow {
  color: #fff !important;
  background-color: transparent !important;
  border-color: #ffbe00 !important;
}

.btn-yellow:hover {
  color: #000 !important;
  background-color: #ffbe00 !important;
  border-color: #ffbe00 !important;
}

.tranparent-bg-black {
  background: rgb(103, 13, 13);
  background: linear-gradient(
    90deg,
    rgba(103, 13, 13, 0) 0%,
    rgba(91, 9, 9, 0.6997986694677871) 17%,
    rgba(99, 10, 10, 1) 50%,
    rgba(103, 10, 10, 0.7166053921568627) 82%,
    rgba(91, 9, 9, 0) 100%
  );
  position: relative;
}

.border-info {
  overflow: hidden;
  border: 2px solid;
  border-radius: 8px;
  border-color: #ffbe00 !important;
  z-index: 200;
  position: relative;
}

.border-ag {
  --r: 20px; /* radius */
  --b: 2px; /* border width */

  background: linear-gradient(
    90deg,
    rgba(123, 95, 43, 1) 0%,
    rgba(227, 193, 102, 1) 31%,
    rgba(229, 215, 159, 1) 50%,
    rgba(227, 193, 102, 1) 70%,
    rgba(123, 95, 43, 1) 100%
  );
  background-clip: text;
  color: transparent;

  border-radius: var(--r);
  margin: 0 auto;
  position: relative;
  z-index: 0;
  text-decoration: none;
}

.border-ag::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  border: var(--b) solid transparent;
  border-radius: var(--r);
  background: inherit;
  background-origin: border-box;
  background-clip: border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.border-ufa {
  border: 2px solid !important;
  border-radius: 8px !important;
  border-color: #ffbe00 !important;
}

.border-ufa-grey {
  overflow: hidden;
  border: 2px solid;
  border-radius: 8px;
  border-color: #3d3d3d !important;
  z-index: 200;
  position: relative;
}

.border-udiamond {
  border-color: var(--border_info_color) !important;
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
}

.zoom {
  transition: transform 0.2s;
}

.zoom:hover {
  transform: scale(1.1);
}

.shine {
  background-color: none;
  background-repeat: no-repeat;
}

.shine:after {
  content: "";
  position: absolute;
  top: -50%;
  left: -60%;
  width: 20%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}

.shine:hover:after {
  opacity: 1;
  left: 130%;
  transition-property: left, top, opacity;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-timing-function: ease;
}

.shine:active:after {
  opacity: 0;
}

#startdate {
  padding-top: 3px;
  border-radius: 3px;
  padding-left: 5px;
  border: none;
}

#enddate {
  padding-top: 3px;
  border-radius: 3px;
  padding-left: 5px;
  border: none;
}

.title-box {
  float: left;
  height: 50px;
  background-color: #191713;
  padding-right: 10px;
  max-width: 40%;
  z-index: 500;
}

.view-background-spacer {
  background-color: #ffbe00;
  width: auto;
  display: block;
  margin-top: 13px;
  height: 2px;
}

.view-background-spacer-ag {
  background: linear-gradient(
    90deg,
    rgba(123, 95, 43, 1) 0%,
    rgba(227, 193, 102, 1) 31%,
    rgba(229, 215, 159, 1) 50%,
    rgba(227, 193, 102, 1) 70%,
    rgba(123, 95, 43, 1) 100%
  );
  width: auto;
  display: block;
  margin-top: 13px;
  height: 2px;
}

.view-background-spacer-height-white {
  opacity: 0.6;
  border: 1px solid #ffffff;
}
