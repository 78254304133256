.margin-game-jack {
  margin: -6px;
}

.margin-campgame-items {
  margin: -2px;
}

.margin-top-nav {
  margin-top: 12px;
}

.margin-camp-game {
  margin-top: 100px;
}

.margin-top-5-decrese {
  margin-top: -5px;
}

.margin-top-10-decrese {
  margin-top: -10px;
}

.margin-top-decrese-icongame {
  margin-top: -15px;
}

.margin-top-20-decrese {
  margin-top: -20px;
}

.margin-top-box-game {
  margin-top: -20px;
}

.margin-top-box-game-img {
  margin-top: -30px;
}

.margin-top-play {
  margin-top: -55px;
}

.margin-top-card {
  margin-top: 13px;
}

.margin-top-3 {
  margin-top: 3px;
}

.margin-top-6 {
  margin-top: 6px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-18 {
  margin-top: 18px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-60 {
  margin-top: 60px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-15 {
  margin-right: 16px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-15 {
  margin-left: 16px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-bottom-10-decrese {
  margin-bottom: -10px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-35 {
  margin-bottom: 35px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-bottom-padding {
  margin-bottom: 60vh;
}

.pading-box-game {
  padding-left: 9.5px;
  padding-right: 1.5px;
}

.all-margin {
  margin-bottom: 5px;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
}

.padding-content {
  padding: 16px;
}

.margin-top-footer {
  margin-top: 150px;
}

.padding-content-small {
  padding: 8px;
}

.logo-snambet-footer {
  width: 240px;
  height: 100px;
  object-fit: contain;
}

.arrow-left-right {
  margin-top: -64px;
  padding-left: 0px;
  padding-right: 0px;
}
