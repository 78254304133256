.nav {
  padding-top: 5px;
  padding-bottom: 5px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #050507;
  display: flex;
  overflow-x: auto;
  z-index: 300;
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 40px;
  overflow: hidden;
  white-space: nowrap;
  font-family: sans-serif;
  font-size: 12px;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}

.nav-link-color {
  color: #f1ba42;
}

.nav-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 40px;
  overflow: hidden;
  white-space: nowrap;
  font-family: sans-serif;
  font-size: 12px;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}

.icon-dropdown-navigation {
  width: 30px;
  height: 30px;
}
