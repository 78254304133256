.logo-snam {
  width: 100px;
  margin-top: 3px;
  height: 42px;
  object-fit: contain;
}

.icon-jackpot-mobile {
  width: 25px;
  height: 25px;
}

.icon-jackpot {
  width: 25px;
  margin-top: 13px;
  height: 25px;
}

.icon-menu {
  width: 50px;
  height: 55px;
}

.children-top {
  margin-top: 69px;
  margin-bottom: 100px;
}

.children-top-margin-top-10 {
  margin-top: 79px;
  margin-bottom: 20px;
}

.children-top-margin-top-20 {
  margin-top: 89px;
  margin-bottom: 100px;
}

.children-top-margin-top-30 {
  margin-top: 99px;
  margin-bottom: 100px;
}

@media only screen and (min-width: 1201px) {
  .play-lotto {
    padding-top: 44px;
    padding-bottom: 44px;
  }
  .icon-play-lotto {
    width: 120px;
    height: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .play-lotto {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .icon-play-lotto {
    width: 70px;
    height: auto;
  }
}

@media only screen and (max-width: 992px) {
  .play-lotto {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .icon-play-lotto {
    width: 70px;
    height: auto;
  }
}

@media only screen and (max-width: 711px) {
  .play-lotto {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .icon-play-lotto {
    width: 70px;
    height: auto;
  }
}

@media only screen and (max-width: 590px) {
  .icon-play-lotto {
    width: 45px;
    height: auto;
  }
}

@media only screen and (max-width: 589px) {
  .play-lotto {
    margin-top: 0px;
  }
  .icon-play-lotto {
    width: 35px;
    height: auto;
  }
}

@media only screen and (min-width: 599px) {
  .jackpot-header-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .jackpot-header {
    display: none !important;
  }
  /*  .sticky-mobile-jackpot {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    background-color: black !important;
    z-index: 1 !important;
  } */
}

@media only screen and (max-width: 388px) {
  .children-top {
    margin-top: 69px;
    margin-bottom: 100px;
  }
}
