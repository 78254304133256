.msg-send {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #278e7a;
  color: #ffffff;
  padding: 10px;
  word-break: break-all;
}

.msg-vender {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #151515;
  color: #ffffff;
  padding: 10px;
  word-break: break-all;
}

.font-timechat {
  color: #242424;
  opacity: 0.5;
}

.bg-color-bottom {
  background-color: #000000;
}

.bg-color-chat-field {
  background-color: #232323;
  border-color: #282828;
  color: #ffffff;
}

.modal-chat {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.view-background-chat {
  overflow: hidden;
  background-color: #020203d5;
  z-index: 200;
  height: 100%;
  color: rgb(211, 211, 211);
  border-radius: 10px;
}

.btn-purple-chat {
  background: #ffbe00;
  color: #000000;
}

.btn-purple-close-chat {
  display: block;
  width: 100%;
  border-style: solid;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  border-color: #ffbe00;
}

.btn-purple-close-chat:hover {
  display: block;
  width: 100%;
  border-style: solid;
  color: #000000;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  border-color: #ffbe00;
  background: #ffbe00;
}
