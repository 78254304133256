@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-down) {
  0.0% {
    transform: rotate(-10deg);
  }
  100% {
    bottom: -20x;
    transform: rotate(10deg);
  }
  19.7% {
    bottom: -20x;
    transform: rotate(10deg);
  }
  39.9% {
    bottom: -20x;
    transform: rotate(-10deg);
  }
  60.2% {
    bottom: -20x;
    transform: rotate(10deg);
  }
  80.1% {
    bottom: -20x;
    transform: rotate(-10deg);
  }
}

.ubox {
  @include animation("slide-down 3s infinite normal none");
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.uspin {
  -webkit-animation: spin 20s linear infinite;
  -moz-animation: spin 20s linear infinite;
  animation: spin 20s linear infinite;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(flip-down) {
  0.0% {
  }
  50.0% {
    transform: translate(0px, 5px);
  }
}

.checkin {
  @include animation("flip-down 1s infinite normal none");
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(animation-shake) {
  0.0% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
  19.7% {
    transform: rotate(5deg);
  }
  39.9% {
    transform: rotate(-5deg);
  }
  60.2% {
    transform: rotate(5deg);
  }
  80.1% {
    transform: rotate(-5deg);
  }
  100.0% {
    transform: rotate(-5deg);
  }
}

.shake {
  @include animation("animation-shake 1s infinite normal none");
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.zoom {
  transition: transform 0.2s;
}

.zoom:hover {
  transform: scale(1.12);
}

.shine {
  background-color: none;
  background-repeat: no-repeat;
}

.shine:after {
  content: "";
  position: absolute;
  top: -50%;
  left: -60%;
  width: 20%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}

.shine:hover:after {
  opacity: 1;
  left: 130%;
  transition-property: left, top, opacity;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-timing-function: ease;
}

.shine:active:after {
  opacity: 0;
}

.spincoin {
  -webkit-animation-name: spinnercoin;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 1.7s;
  animation-name: spinnercoin;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 1.7s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* WebKit and Opera browsers */
@-webkit-keyframes spinnercoin {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(-360deg);
  }
} /* all other browsers */
@keyframes spinnercoin {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  to {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
  }
}

.shine-img {
  display: inline-block;
  height: 200px;
  width: 200px;
  position: relative;
  overflow: hidden;
}
.shine-img Img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.shine-img:before {
  content: "";
  z-index: 10;
  position: absolute;
  height: 200%;
  width: 200%;
  top: -120%;
  left: -120%;
  background: linear-gradient(
    transparent 0%,
    rgba(255, 255, 255, 0.1) 45%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.1) 55%,
    transparent 100%
  );
  transition: all 1s;
  transform: rotate(-45deg);
  animation: shining 10s infinite forwards;
}
@keyframes shining {
  0% {
    top: -120%;
    left: -120%;
  }
  20% {
    left: 100%;
    top: 100%;
  }
  40% {
    left: 100%;
    top: 100%;
  }
  100% {
    left: 100%;
    top: 100%;
  }
}
