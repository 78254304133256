.gradient-border {
  --border-width: 4px;
  border-radius: var(--border-width);
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  background: linear-gradient(
    60deg,
    hsl(44, 100%, 72%),
    hsl(48, 100%, 52%),
    hsl(29, 97%, 54%),
    hsl(41, 100%, 67%),
    hsl(44, 85%, 66%),
    hsl(41, 100%, 93%),
    hsl(36, 85%, 66%),
    hsl(39, 100%, 51%)
  );
  background-size: 300% 300%;
  background-position: 0 50%;
  animation: moveGradient 1.5s alternate infinite;
}

@keyframes moveGradient {
  50% {
    box-shadow: 0 0 5px #f7b53c;
    background-position: 100% 50%;
  }
}
