.outerview {
  background: #3a4269;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
}

.sub-heading {
  padding: 20px 15px 0px 20px;
}

.main-heading {
  padding: 0px 100px 20px 20px;
}

.icon-section > div:first-child {
  margin-left: -10%;
}

.icon-section > div:last-child {
  margin-left: -30%;
}

.icon-holder {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.icon-review-box {
  border-radius: 10px;
  padding-left: 100px;
  animation: slideShow 50s infinite;
  box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.1);
}

@keyframes slideShow {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-200px);
  }
}
