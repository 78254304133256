.main-content {
  padding-bottom: 60px;
}

.center-content {
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.nav-align-layout {
  align-items: center;
}

.center-row-content {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.center-content {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.center-content {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 10px;
}

.center-row-content-end {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text_inline {
  display: inline-block;
}

.float-right-img {
  float: right;
}

.float-right-rps {
  float: right;
}

.float-left-img {
  float: left;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-center-rps {
  text-align: center;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.center-ver {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.center-div {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.right {
  position: absolute;
  right: 0px;
}

.text-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-center {
  text-align: center;
}

.img-left {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
@media only screen and (max-width: 500px) {
}
