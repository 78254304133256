html,
body {
  padding: 0;
  margin: 0;
  color: #e3e3e3;
  background: #191713;
  line-height: 1.7em;
  font-family: Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
}

h4 {
  font-size: 20px;
}

h2 {
  font-size: 20px;
}

h1 {
  font-size: 24px;
}

.select-div {
  cursor: pointer !important;
}

button {
  border: none;
}

textarea {
  width: 500px;
  height: 100px;
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
  border-color: -moz-use-text-color #ffffff #ffffff -moz-use-text-color;
  border-image: none;
  border-radius: 6px 6px 6px 6px;
  border-style: none solid solid none;
  border-width: medium 1px 1px medium;
  box-shadow: 0 1px 2px rgba(43, 5, 53, 0.12) inset;
  color: whitesmoke;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.4em;
  padding: 5px 8px;
  transition: background-color 0.2s ease 0s;
}

textarea:focus {
  background: none repeat scroll 0 0 #1f0031;
  outline-width: 0;
  color: whitesmoke;
}

.floating-btn {
  width: 70px;
  height: 35px;
  background: transparent;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 50%;
  color: #f1f1f1;
  border: none;
  position: fixed;
  right: -15px;
  bottom: 130px;
  opacity: 1;
  z-index: 500 !important;
}
.floating-btn:focus {
  border-color: transparent;
  outline: none;
}

.floating-btn-2 {
  width: 70px;
  height: 35px;
  background: transparent;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 50%;
  color: #f1f1f1;
  border: none;
  position: fixed;
  right: -15px;
  bottom: 220px;
  opacity: 1;
  z-index: 1;
}
.floating-btn-2:focus {
  border-color: transparent;
  outline: none;
}

.floating-noti {
  width: 70px;
  height: 35px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 50%;
  color: #f1f1f1;
  border: none;
  position: fixed;
  right: -14px;
  bottom: 130px;
  opacity: 1;
  z-index: 2;
}
.bg-notification {
  background-color: #ff3d3dd8;
  padding-left: 8px;
  padding-right: 8px;

  border-radius: 100px;
}

.floating-noti-vender {
  width: 70px;
  height: 35px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 50%;
  color: #f1f1f1;
  border: none;
  position: fixed;
  right: -14px;
  bottom: 220px;
  opacity: 1;
  z-index: 2;
}
.bg-notification-vender {
  background-color: #ff3d3dd8;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 100px;
}

.review-size-and-scroll {
  height: 300px;
  overflow-y: scroll;
}

.bg-nav-background-color {
  background-color: #000000;
  height: 65px;
}

.full-width-image {
  width: 100%;
  height: auto;
}

.frame-bank {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

.view-background-blue {
  overflow: hidden;
  border: 1px solid;
  border-radius: 9px;
  border-color: #98aeff54;
  z-index: 200;
  position: relative;
}

.card-add-bg {
  background: transparent;
}

.card-table {
  color: #ffffff;
  text-align: center;
}

.card-add-headings,
.card-add {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.card-add-heading {
  flex-basis: 33.333%;
  font-weight: bold;
}

.card-add {
  border-radius: 1px;
  margin-bottom: 5px;
}

.font-card-add {
  flex-basis: 33.333%;
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 10px;
  border-bottom: solid 0.5px #ffffff;
}

.view-background-black {
  background: #191713;
  border: 2px solid #2d2525;
}

.view-background-deposit-withdraw {
  overflow: hidden;
  background: #191713;
  border: 2px solid #2d2525;
  z-index: 200;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
}

.view-background-transparent {
  overflow: hidden;
  background-color: #020203d5;
  z-index: 200;
  position: relative;
  color: rgb(211, 211, 211);
  border-radius: 10px;
}

.modal-dialog {
  top: 5px;
  max-width: none;
  margin: 0;
}

.modal-alert {
  top: 5px;
  max-width: none;
  margin: 0;
  margin-left: 30px;
  margin-right: 30px;
}

.modal-header {
  border-bottom: 1px solid #f9f9fa00;
  margin-left: 5px;
  margin-right: 5px;
  justify-content: center;
}

.modal-header-box-game {
  border-bottom: 1px solid #f9f9fa00;
  margin-top: 5px;
  margin-right: 15px;
  justify-content: center;
  padding: 2px;
}

.modal-footer {
  border-top: 1px solid #f9f9fa00;
  margin-left: 5px;
  margin-right: 5px;
}

.modal-color-black {
  background-color: #f9f9fa;
  opacity: 0.95;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 -10px #009fde;
  }
  40% {
    box-shadow: 0 0 20px #009fde;
  }
  60% {
    box-shadow: 0 0 20px #009fde;
  }
  100% {
    box-shadow: 0 0 -10px #009fde;
  }
}

.view-glowing {
  animation: glowing 1000ms infinite;
}

@keyframes gold-glowing {
  0% {
    box-shadow: 0 0 -10px #f7b53c;
  }
  40% {
    box-shadow: 0 0 20px #f7b53c;
  }
  60% {
    box-shadow: 0 0 20px #f7b53c;
  }
  100% {
    box-shadow: 0 0 -10px #f7b53c;
  }
}

.button-glowing {
  animation: gold-glowing 1000ms infinite;
}

.line-ufa {
  border: solid 0.5px #b19560;
}

.circle-tag {
  height: 8px;
  width: 8px;
  background-color: #de0013;
  border-radius: 50%;
  display: inline-block;
  animation: circle-tag 1000ms infinite;
}

@keyframes circle-tag {
  0% {
    box-shadow: 0 0 -10px #de0013;
  }
  40% {
    box-shadow: 0 0 20px #de0013;
  }
  60% {
    box-shadow: 0 0 20px #de0013;
  }
  100% {
    box-shadow: 0 0 -10px #de0013;
  }
}

.blog-card-footer:last-child {
  border-radius: 0 !important;
}

.blog-card-footer {
  padding: 0 !important;
  border-top: unset !important;
}

.blog-card-background {
  background-color: #000000bc;
}

.blog-card-img-overlay {
  position: absolute;
  top: 50;
  right: 0;
  bottom: 0;
  left: 0;
}

#menu__toggle {
  opacity: 0;
}

#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
}
#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  visibility: visible;
  left: 0;
}

.menu__btn {
  display: flex;
  align-items: center;
  position: fixed;
  top: 20px;
  left: 20px;

  width: 26px;
  height: 26px;

  cursor: pointer;
  z-index: 1;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;

  width: 100%;
  height: 2px;

  background-color: #ffbe00;

  transition-duration: 0.25s;
}
.menu__btn > span::before {
  content: "";
  top: -8px;
}
.menu__btn > span::after {
  content: "";
  top: 8px;
}

.menu__box {
  display: block;
  position: fixed;
  visibility: hidden;
  top: 0;
  left: -100%;

  width: 300px;
  height: 100%;

  margin: 0;
  padding: 80px 0;

  list-style: none;

  background-color: #000000;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);

  transition-duration: 0.25s;
}

.menu__item {
  display: block;
  padding: 12px 24px;

  color: #ffbe00;

  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 600;

  text-decoration: none;

  transition-duration: 0.25s;
  text-align: left;
}
.menu__item:hover {
  background-color: #ffbe00;
  color: white;
}

.icon-dropdown-play {
  width: 120px;
  height: 120px;
}

.view-background-popup {
  overflow: hidden;
  background-color: #02020300;
  z-index: 200;
  position: relative;
  color: rgb(211, 211, 211);
  border: none;
}

@media all and (min-width: 800px) {
  .modal-popup {
    top: 5px;
    max-width: 500px;
    text-align: center;
    margin: auto;
  }
}

@media all and (max-width: 799px) {
  .modal-popup {
    top: 5px;
    max-width: none;
    padding: 10px;
    margin: 0;
  }
}

.modal-backdrop {
  opacity: 0.7 !important;
}
